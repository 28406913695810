import { sectionItem } from '@/services/Surveys/SectionModal/store'

import $ from 'jquery'
import { notifyError } from '@/services/notify'
import _get from 'lodash/get'

export const methods = {
  async reload() {
    this.loading = true

    console.debug('this.section=' + JSON.stringify(this.section))

    this.surveyHeaderID = _get(this, '$route.params.id', 0)
    console.log('surveyHeaderID=' + this.surveyHeaderID)

    if (this.section && this.section != undefined) {
      //Update
      this.buttonText = 'Save'

      this.formData.surveySectionID = this.section.surveySectionID
      this.formData.name = this.section.name
      this.formData.title = this.section.title

      this.loading = false
    } else {
      this.buttonText = 'Add'
      this.loading = false
    }

    this.loading = false
  },

  open() {
    this.formData = {
      surveySectionID: 0,
      name: '',
      title: ''
    }

    this.loading = false
  },

  createSurveySectionModal() {
    console.log('createSurveySectionModal...')
    this.section = null
    this.toggle = true
  },

  async submitSection() {
    if (this.formData && this.formData != undefined) {
      if (this.formData.surveySectionID && this.formData.surveySectionID > 0) {
        await this.updateSurveySection()
      } else {
        await this.addSurveySection()
      }
    }
  },

  async addSurveySection() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem processing this survey section.')
      return
    }

    this.loading = true

    await sectionItem.dispatch('addSurveySection', {
      surveyHeaderID: this.surveyHeaderID || 0,
      name: this.formData.name ? this.formData.name : '',
      title: this.formData.title ? this.formData.title : '',
      done: () => {
        // auto-close modal
        this.$emit('update:toggle', (this.toggle = false))
        $('#clickclose').click()
        this.loading = false
      }
    })
  },

  async updateSurveySection() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem updating this survey.')
      return
    }

    this.loading = true

    const payload = {
      surveySectionID: this.formData.surveySectionID,
      surveyHeaderID: this.section.surveyHeaderID,
      name: this.formData.name ? this.formData.name : '',
      title: this.formData.title ? this.formData.title : ''
    }

    await sectionItem.dispatch('updateSurveySection', {
      payload: payload,
      done: () => {
        // auto-close modal
        this.$emit('update:toggle', (this.toggle = false))
        $('#clickclose').click()
        this.loading = false
      }
    })
  }
}

<template>
  <div section-modal>
    <div class="is-size-4" v-if="formData">{{ buttonText }} Section</div>
    <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(submitSection)">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <label class="label"
                    >Name<Info title="Name" information="Represents the text of the survey section."
                  /></label>
                  <valid-input
                    v-model="formData.name"
                    name="name"
                    type="text"
                    vid="name"
                    placeholder="Name"
                    rules="required|max:80"
                  />
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <label class="label"
                    >Title<Info
                      title="Title"
                      information="Represents the title of the survey section."
                  /></label>
                  <valid-input
                    v-model="formData.title"
                    name="title"
                    type="text"
                    vid="title"
                    placeholder="Title"
                    rules="required|max:100"
                  />
                </div>
                <div class="pt-5">
                  <button
                    type="submit"
                    tests-id="survey-modal-button"
                    :disabled="loading"
                    class="button is-primary is-rounded"
                  >
                    {{ buttonText }}
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'
import Info from '@/components/icons/Info'

export default {
  props: {
    section: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    Info
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>

<template>
  <div questions>
    <div
      question
      v-for="(question, indexA) in list"
      :key="indexA"
      :style="{
        justifyContent: 'space-between',
        ...(indexA !== list.length - 1 ? { marginBottom: '6px' } : {}),
        padding: '0px 10px'
      }"
    >
      <div class="pb-5 pr-6" :style="{ flexBasis: '33%' }">
        <div
          class="columns pb-5 pr-5 is-11"
          :style="{ color: color[8], height: '27px' }"
          v-if="question && question.name && question.name != undefined"
        >
          <div class="column is-11">
            <span
              class="pl-6 pr-5"
              style="vertical-align:text-bottom !important; padding-top:10px;"
            >
              <button
                style="vertical-align: bottom;"
                title="Edit Question"
                class="w-7 h-7 ml-4 pl-6"
                @click.prevent="updateQuestionModal(question)"
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  ></path>
                </svg>
              </button>
              <button
                style="vertical-align: bottom;"
                title="Delete Question"
                class="w-7 h-7 ml-4"
                @click.prevent="
                  confirmDeleteQuestion(
                    question.questionID,
                    question.name,
                    question.surveySectionID
                  )
                "
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <span class="pl-5">{{
                `(${processQuestionType(question.questionType.name)}) ${question.label}`
              }}</span>
            </span>
          </div>
          <div class="is-right pr-6">
            <button
              @click.prevent="
                AddQuestionOption(
                  question,
                  question.surveySectionID,
                  question.questionType.codeName
                )
              "
              :disabled="
                (question.questionType.codeName == 'scale' ||
                  question.questionType.codeName == 'text') &&
                question.questionOptions.length == 1
                  ? true
                  : false
              "
              type="button"
              class="button is-info is-small is-outline is-rounded"
            >
              Add Option / Answer
            </button>
          </div>
        </div>
        <div v-else :style="{ color: color[8] }">
          N/A
        </div>
      </div>

      <div
        question-options
        v-for="(questionOption, indexA) in question.questionOptions"
        :key="indexA"
        :style="{
          justifyContent: 'space-between',
          ...(indexA !== list.length - 1 ? { marginBottom: '6px' } : {}),
          padding: '0px 10px'
        }"
      >
        <div v-if="questionOption && questionOption.content" class="column is-6 pl-6">
          <span class="pl-4 pr-5" style="vertical-align:text-bottom !important; padding-top:10px;"
            ><span class="pl-4">
              <button
                style="vertical-align: bottom;"
                title="Edit Question"
                class="w-7 h-7 ml-4 pl-6"
                @click.prevent="
                  updateQuestionOptionModal(
                    questionOption,
                    question.surveySectionID,
                    question.questionType.codeName
                  )
                "
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  ></path>
                </svg>
              </button>
              <button
                style="vertical-align: bottom;"
                title="Delete Question"
                class="w-7 h-7 ml-4"
                @click.prevent="
                  confirmDeleteQuestionOption(
                    questionOption.questionOptionID,
                    questionOption.content,
                    question.surveySectionID
                  )
                "
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>

              <span class="pl-4">{{ `${questionOption.content}` }}</span
              ><span v-if="question.questionType.codeName == 'scale'">{{
                `: ${questionOption.scaleMin || 0} to ${questionOption.scaleMax || 0}`
              }}</span></span
            ></span
          >
        </div>
      </div>
      <div
        class="pl-6"
        question-options-not-found
        v-if="!Array.isArray(question.questionOptions) || question.questionOptions.length === 0"
        :style="{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '6px',
          padding: '8px 12px',
          borderRadius: '6px',
          color: color[8]
        }"
      >
        <span class="pl-6"
          ><span class="pl-5">Question Options were not found for this question.</span></span
        >
      </div>
    </div>

    <div
      questions-not-found
      v-if="!Array.isArray(list) || list.length === 0"
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '6px',
        padding: '8px 12px',
        borderRadius: '6px',
        color: color[8]
      }"
    >
      Questions were not found.
    </div>
    <Modal :toggle.sync="questionToggle">
      <QuestionModal :surveySectionID="selectedSurveySectionID" :question="selectedQuestion" />
    </Modal>
    <Modal :toggle.sync="questionOptionToggle">
      <QuestionOptionModal
        :questionID="selectedQuestionID"
        :questionOption="selectedQuestionOption"
        :questionType="questionTypeCodeName"
      />
    </Modal>
  </div>
</template>

<script>
import _get from 'lodash/get'
import { methods } from './keys/methods'
import QuestionModal from './../QuestionModal'
import QuestionOptionModal from './../QuestionOptionModal'
import Modal from '@/components/Modal'
/*
  <Questions :questions="questions" />
*/
export default {
  props: {
    questions: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    const theme = _get(this, ['$store', 'state', 'theme'], {})

    return {
      color: theme.color,
      list: this.questions,
      questionToggle: false,
      questionOptionToggle: false,
      selectedQuestion: null,
      selectedQuestionOption: null,
      selectedSurveySectionID: 0,
      selectedQuestionID: 0,
      questionTypeCodeName: ''
    }
  },

  components: {
    QuestionModal,
    QuestionOptionModal,
    Modal
  },

  mounted() {},

  methods,

  watch: {
    questionToggle() {
      console.log('in toggle...')
      if (this.questionToggle == false) {
        this.reload(this.selectedSurveySectionID)
        this.questionToggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    },
    questionOptionToggle() {
      console.log(
        'in question option toggle...selectedSurveySectionID=' + this.selectedSurveySectionID
      )
      if (this.questionOptionToggle == false) {
        this.reload(this.selectedSurveySectionID)
        this.questionOptionToggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  }
}
</script>

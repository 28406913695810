export function data() {
  return {
    formData: {
      questionID: 0,
      questionTypeID: 0,
      documentID: 0,
      name: '',
      isMultipleOption: false
    },
    questionTypeList: null,
    activeStep: 0,
    newId: 0,
    isMultipleOption: false,
    isDebug: true,
    isOpen: false,
    loading: true,
    buttonText: 'Add',
    styles: {
      stepItems: {
        class: 'step-items',
        display: 'none'
      }
    }
  }
}

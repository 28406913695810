import { questionItem } from '@/services/Surveys/QuestionModal/store'
import { questionOptionItem } from '@/services/Surveys/QuestionOptionModal/store'

export const methods = {
  updateQuestionModal(question) {
    if (question) {
      this.questionToggle = true
      this.selectedQuestion = question
      this.selectedSurveySectionID = question.surveySectionID
    }
  },
  confirmDeleteQuestion(id, name, sectionID) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Question',
      message: `Are you sure you want to <b>delete</b> the question for '${name}'?`,
      confirmText: 'Delete Question',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteQuestion(id, sectionID)
    })
  },

  async deleteQuestion(id, sectionID) {
    this.selectedQuestion = null
    await questionItem.dispatch('deleteQuestion', {
      questionID: id,
      done: () => {
        this.loading = false
        this.reload(sectionID)
      }
    })
  },

  processQuestionType(qtype) {
    if (qtype) {
      if (qtype === 'Radio-Button') {
        return 'Single Selection'
      } else if (qtype === 'Check-Box') {
        return 'Multiple Selections'
      } else if (qtype === 'Text') {
        return 'Text'
      } else if (qtype === 'Scale') {
        return 'Scale'
      } else {
        return qtype
      }
    }
  },

  async reload(sectionID) {
    this.selectedSurveySectionID = sectionID

    await questionItem
      .dispatch('getQuestionList', {
        surveySectionID: sectionID
      })
      .then(({ list }) => {
        if (list) {
          list = list.map(v => ({
            ...v,
            label: v.name ? v.name : ''
          }))

          let counter = 0
          Array.from(list).forEach(e => {
            counter++
            if (e && e.name && e.name != undefined) {
              e.label = `${counter}. ${e.name}`
            }
          })
        }
        this.list = list
      })
  },

  confirmDeleteQuestionOption(id, name, sectionID) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Option',
      message: `Are you sure you want to <b>delete</b> the question option for '${name}'?`,
      confirmText: 'Delete Option',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteQuestionOption(id, sectionID)
    })
  },

  async deleteQuestionOption(id, sectionID) {
    this.selectedQuestionOption = null
    await questionOptionItem.dispatch('deleteQuestionOption', {
      questionOptionID: id,
      done: () => {
        this.loading = false
        this.reload(sectionID)
      }
    })
  },

  async AddQuestionOption(question, sectionID, questionType) {
    this.selectedSurveySectionID = sectionID
    this.selectedQuestionID = question.questionID
    this.selectedQuestionOption = null
    this.questionTypeCodeName = questionType
    this.questionOptionToggle = true
  },

  async updateQuestionOptionModal(questionOption, sectionID, questionType) {
    this.selectedSurveySectionID = sectionID
    this.selectedQuestionID = questionOption.questionID
    this.selectedQuestionOption = questionOption
    this.questionTypeCodeName = questionType
    this.questionOptionToggle = true
  }
}

/*
  import { sectionItem } from '@/services/Surveys/SectionModal/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  // Update Survey Section
  async updateSurveySection({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/Surveys/SurveySection`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.surveySectionID > 0) {
        notifyMessage(`Successfully updated the survey section.`)
        done()
      } else {
        notifyError('There was a problem updating this survey section.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this survey section.`)
      console.error(exception)
    }
  },

  // Add Survey Section
  async addSurveySection({ dispatch }, { surveyHeaderID, name, title, done }) {
    if (isDebug == true) console.debug('...in addSurveySection' + surveyHeaderID)
    try {
      const results = await kms.post(`/Surveys/SurveySection`, {
        surveyHeaderID,
        name,
        title
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.surveySectionID > 0) {
        notifyMessage(`Successfully added the survey section.`)
        done()
      } else {
        notifyError('There was a problem adding this survey section.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this survey section.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const sectionItem = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})

/*
  import { questionOptionItem } from '@/services/Surveys/QuestionOptionModal/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'
import _get from 'lodash/get'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async getQuestionOptionList({}, { questionID }) {
    console.log('in getQuestionOptionList...')
    try {
      const result = await kms.get('/Surveys/QuestionOption/List', {
        params: {
          questionID
        }
      })

      if (isDebug == true) console.debug('getQuestionOptionList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The question option list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  // Update Question Option
  async updateQuestionOption({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/Surveys/QuestionOption`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.questionOptionID > 0) {
        notifyMessage(`Successfully updated the question option.`)
        done()
      } else {
        notifyError('There was a problem updating this question option.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this question option.`)
      console.error(exception)
    }
  },

  // Add Question Option
  async addQuestionOption({ dispatch }, { questionID, content, scaleMin, scaleMax, done }) {
    if (isDebug == true) console.debug('...in addQuestionOption')
    try {
      const results = await kms.post(`/Surveys/QuestionOption`, {
        questionID,
        content,
        scaleMin,
        scaleMax
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.questionOptionID > 0) {
        notifyMessage(`Successfully added the question option.`)
        done()
      } else {
        notifyError('There was a problem adding this question option.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this question option.`)
      console.error(exception)
    }
  },

  async deleteQuestionOption({ dispatch }, { questionOptionID, done }) {
    try {
      const path = `/Surveys/QuestionOption/${questionOptionID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the question option.`)
        done()
      } else {
        notifyError('There was a problem deleting the question option.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting this question option.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const questionOptionItem = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})

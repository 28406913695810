import { questionOptionItem } from '@/services/Surveys/QuestionOptionModal/store'
import $ from 'jquery'
import { notifyError } from '@/services/notify'

export const methods = {
  async reload() {
    this.loading = true

    console.debug('this.questionOption=' + JSON.stringify(this.questionOption))

    if (this.questionOption && this.questionOption != undefined) {
      //Update
      this.buttonText = 'Save'

      this.formData.questionID = this.questionOption.questionID
      this.formData.questionOptionID = this.questionOption.questionOptionID
      this.formData.content = this.questionOption.content
      this.formData.minScale = this.questionOption.scaleMin
      this.formData.maxScale = this.questionOption.scaleMax

      this.newId = this.questionOption.questionOptionID

      this.loading = false
    } else {
      this.buttonText = 'Add'
      this.loading = false
    }

    this.loading = false
  },

  open() {
    this.formData = {
      questionID: 0,
      questionOptionID: 0,
      minScale: 0,
      maxScale: 0,
      name: ''
    }

    this.loading = false
  },

  leaveForm() {
    // auto-close modal
    this.$emit('update:toggle', (this.questionOptionToggle = false))
    $('#clickclose').click()
    this.loading = false
  },

  async submitQuestionOption() {
    if (this.formData && this.formData != undefined) {
      if (
        this.questionOption &&
        this.questionOption != undefined &&
        this.formData.questionOptionID > 0
      ) {
        await this.updateQuestionOption()
      } else {
        await this.addQuestionOption()
      }
    }
  },

  async addQuestionOption() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem processing this question option.')
      return
    }

    this.loading = true

    await questionOptionItem.dispatch('addQuestionOption', {
      questionID: this.questionID || 0,
      content: this.formData.content || '',
      scaleMin: this.formData.minScale || 0,
      scaleMax: this.formData.maxScale || 0,
      done: () => {
        this.loading = false
        this.leaveForm()
      }
    })
  },

  async updateQuestionOption() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem updating this question option.')
      return
    }

    this.loading = true

    const payload = {
      questionOptionID: this.formData.questionOptionID,
      questionID: this.formData.questionID,
      scaleMin: this.formData.minScale,
      scaleMax: this.formData.maxScale,
      content: this.formData.content ? this.formData.content : ''
    }

    await questionOptionItem.dispatch('updateQuestionOption', {
      payload: payload,
      done: () => {
        this.loading = false
        this.leaveForm()
      }
    })
  }
}

<template>
  <div class="section question-upload-modal" question-upload-modal>
    <div class="container">
      <div class="is-size-4">Upload Document (optional)</div>
      <div class="box is-8" :style="{ marginTop: '20px' }">
        <div v-if="base64pdf" class="has-text-centered">
          <div v-dragscroll="true" :class="['doc-viewer', { 'zoom-active': scale > 0.9 }]">
            <pdf
              style="display: none;"
              :src="base64pdf"
              @num-pages="pageCount = new Array($event)"
            />
            <!-- Actual document -->
            <pdf
              v-for="(page, index) in pageCount"
              :key="index"
              :src="base64pdf"
              :page="index + 1"
              :scale="scale"
              :resize="true"
              :style="{ margin: 'auto', width: `${100 * scale}%` }"
            />
          </div>

          <div class="doc-controls has-text-center">
            <b-field position="is-centered">
              <div class="control">
                <b-button @click="zoom(-1)">-</b-button>
              </div>
              <div class="control">
                <b-button @click="zoom(1)">+</b-button>
              </div>
            </b-field>
          </div>
        </div>
        <div>
          <!-- Show the Upload Form -->
          <b-field class="file">
            <b-upload v-model="file" drag-drop accept="application/pdf">
              <section class="section has-width-500">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="file-pdf" size="is-large"> </b-icon>
                  </p>

                  <p v-if="file">
                    {{ file.name }}
                  </p>
                  <p v-else>
                    Drop a PDF file here, or click to select a file from your computer.
                  </p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <span
            ><b-button type="is-link is-light" :disabled="!file" @click="submitFile"
              >Upload</b-button
            ></span
          >
          <span class="pl-5"
            ><b-button type="is-link is-light" v-if="base64pdf" @click="removeFile"
              >Remove</b-button
            ></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { mapState } from 'vuex'
import { dragscroll } from 'vue-dragscroll'
import pdf from 'vue-pdf'
import { uploadStore } from '@/services/Surveys/UploadModal/store'
import { notifyError, notifyMessage } from '@/services/notify'
import moment from 'moment'

export default {
  name: 'UploadModal',
  components: {
    pdf
  },

  directives: { dragscroll },

  props: {
    questionID: Number
  },

  data: function() {
    return {
      loading: false,
      isDebug: true,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      file: null,
      base64pdf: '',
      scale: 1,
      pageCount: []
    }
  },

  mounted() {
    if (this.isDebug == true) console.debug('this.questionID=' + this.questionID)
    if (this.questionID > 0) {
      this.loadCurrentPdf()
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {},

  methods: {
    async loadCurrentPdf() {
      try {
        const payload = {
          questionID: this.questionID,
          asBase64: true
        }
        await uploadStore
          .dispatch('loadDocument', {
            payload: payload
          })
          .then(({ baseSixtyFour }) => {
            if (baseSixtyFour) {
              this.base64pdf = baseSixtyFour
            }
          })
      } catch (e) {
        if (this.isDebug == true) console.debug(e)
      }
    },

    closeModal() {
      this.$emit('update:toggle', (this.toggle = false))
    },

    async removeFile() {
      if (this.questionID <= 0) {
        notifyError('There was a problem removing this document.')
        return
      }

      this.loading = true
      try {
        const payload = {
          questionID: this.questionID
        }

        if (this.isDebug == true) console.debug('submit payload=' + JSON.stringify(payload))

        await uploadStore.dispatch('removeFile', {
          payload: payload,
          done: () => {
            this.base64pdf = ''
          }
        })
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    },

    async submitFile() {
      if (this.questionID <= 0) {
        notifyError('There was a problem uploading this document.')
        return
      }

      this.loading = true
      try {
        const payload = {
          file: this.file,
          questionID: this.questionID
        }

        if (this.isDebug == true) console.debug('submit payload=' + JSON.stringify(payload))

        await uploadStore
          .dispatch('uploadDocument', {
            payload: payload
          })
          .then(({ upload }) => {
            if (upload) {
              if (this.isDebug == true) console.debug('upload=' + JSON.stringify(upload))
              this.base64pdf = ''
              setTimeout(() => this.loadCurrentPdf(), 2000)
              notifyMessage(`Successfully uploaded the document.`)
            }
          })
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    },

    zoom(direction) {
      const amt = Math.sqrt(2)
      if (direction > 0) {
        this.scale *= amt
      } else {
        this.scale /= amt
      }

      // constrain to min/max
      this.scale = Math.max(0.5, Math.min(4, this.scale))
    }
  }
}
</script>

<style lang="scss">
.question-upload-modal {
  .modal-h-full {
    left: 50%;
    transform: translate(-50%);
  }

  #request-doc-upload {
    .upload .upload-draggable {
      border: none 0;
      border-radius: 0;
      width: 100%;
      padding: 0;
    }

    .modal-close,
    .delete {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 20;
      margin: 5px 5px 0 0;
    }
  }

  .doc-viewer {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 130%;
    min-height: 40vh;
    max-height: calc(100vh - 320px);
    border: 1px solid #dbdbdb;

    &.zoom-active {
      cursor: grab;
    }
  }

  .doc-controls {
    margin-top: -1em;
  }
}
</style>

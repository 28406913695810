<template>
  <div question-option-modal>
    <div class="is-size-4" v-if="formData">{{ buttonText }} Question Option</div>
    <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(submitQuestionOption)">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <label class="label"
                    >Option Text<Info
                      title="Content"
                      information="Represents the text of the option for the question."
                  /></label>
                  <valid-input
                    v-model="formData.content"
                    name="content"
                    type="text"
                    vid="content"
                    placeholder="Content"
                    rules="required|max:80"
                  />
                </div>
                <div v-if="questionType == 'scale'" class="column is-12" :style="styles.formRow">
                  <span class="column is-12" style="white-space: nowrap">
                    <label class="label"
                      >Minimum Scale<Info
                        title="Minimum Scale"
                        information="Represents the lowest number, of the scale, that can be selected."
                    /></label>
                    <b-field>
                      <b-numberinput
                        style="width:150px;"
                        name="MinScale"
                        vid="minScale"
                        v-model="formData.minScale"
                      />
                    </b-field>
                  </span>
                </div>
                <div v-if="questionType == 'scale'" class="column is-12" :style="styles.formRow">
                  <span class="column is-12" style="white-space: nowrap">
                    <label class="label"
                      >Maximum Scale<Info
                        title="Maximum Scale"
                        information="Represents the highest number, of the scale, that can be selected."
                    /></label>
                    <b-field>
                      <b-numberinput
                        style="width:150px;"
                        name="MaxScale"
                        vid="maxScale"
                        v-model="formData.maxScale"
                      />
                    </b-field>
                  </span>
                </div>
                <div class="pt-5">
                  <button
                    type="submit"
                    tests-id="option-modal-button"
                    :disabled="loading"
                    class="button is-primary is-rounded"
                  >
                    {{ buttonText }}
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'
import Info from '@/components/icons/Info'

export default {
  props: {
    questionID: Number,
    questionType: String,
    questionOption: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    Info
  },

  mounted() {
    if (this.questionOption && this.questionOption != undefined) {
      this.newId = this.questionOption.questionOptionID

      console.debug('mounted this.newId=' + this.newId)
    }
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>

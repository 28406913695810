export function data() {
  return {
    formData: {
      questionID: 0,
      questionOptionID: 0,
      minScale: 0,
      maxScale: 0,
      content: ''
    },
    activeStep: 0,
    newId: 0,
    isDebug: true,
    isOpen: false,
    loading: true,
    buttonText: 'Add',
    styles: {
      formRow: {
        padding: '10px',
        whiteSpace: 'nowrap'
      }
    }
  }
}

import _get from 'lodash/get'
import { surveySectionList } from '@/services/Surveys/SurveySection/store'
import Details from './../components/Details'
import SectionEditDeleteButton from './../components/SectionEditDeleteButton'
import Button from '@/components/buttons/Button'

export const methods = {
  rowOnClick() {},

  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const surveySectionID = _get(entry, 'surveySectionID', 0)
      let name = _get(entry, 'name', '')
      let sectionQuestions = _get(entry, 'questions', null)
      const isSimple = _get(entry, 'isSimpleWithSimpleHeader', false)

      if (isSimple == true) {
        this.isSimple = true
        name = ''
      } else {
        this.isSimple = false
      }

      console.debug('isSimple=' + isSimple + ',' + this.isSimple)

      if (sectionQuestions) {
        sectionQuestions = sectionQuestions.map(v => ({
          ...v,
          label: v.name ? v.name : ''
        }))

        console.log('list=' + JSON.stringify(sectionQuestions))

        let counter = 0
        Array.from(sectionQuestions).forEach(e => {
          counter++
          if (e && e.name && e.name != undefined) {
            e.label = `${counter}. ${e.name}`
          }
        })
      }

      if (this.isDebug == true)
        console.debug(surveySectionID + 'questions==' + JSON.stringify(sectionQuestions))

      return {
        _expandable: {
          expanded: isSimple ? true : false,

          // whether or not to show the expandable icon
          show: true,

          showIcon: isSimple ? false : true,

          // the component to show when the expandable icon is pressed
          component: Details,

          // props to send to the expandable icon
          props: {
            questions: sectionQuestions
          }
        },
        editDelete: {
          component: SectionEditDeleteButton,
          props: {
            updateSectionModal: () => {
              this.updateSectionModal(entry)
            },
            confirmDeleteSection: () => {
              this.confirmDeleteSection(surveySectionID, name)
            },
            visible: !isSimple
          }
        },
        name: name,
        addQuestion: {
          component: Button,
          props: {
            onClick: () => {
              this.createQuestionModal(surveySectionID)
            },
            text: 'Add Question'
          }
        }
      }
    })

    this.rows = rows
  },

  updateSectionModal(editSection) {
    this.selectedSection = editSection
    this.toggle = true
  },

  confirmDeleteSection(id, name) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Section',
      message: `Are you sure you want to <b>delete</b> the section for '${name}'?`,
      confirmText: 'Delete Section',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteSurveySection(id)
    })
  },

  createQuestionModal(surveySectionID) {
    this.selectedSectionID = surveySectionID
    this.questionToggle = true
  },

  async deleteSurveySection(id) {
    this.selectedSurvey = null
    await surveySectionList.dispatch('deleteSurveySection', {
      surveySectionID: id,
      done: () => {
        this.loading = false
        this.reload()
      }
    })
  },

  async loadSurveySections() {
    if (this.isDebug == true) console.debug('in loadSurveySections()...')

    this.isSimple = false

    await surveySectionList
      .dispatch('getSurveySectionList', {
        surveyHeaderID: this.surveyHeaderID,
        includeAnswerResults: false
      })
      .then(({ list }) => {
        this.determineRows(list)
      })
  },

  returnToParent() {
    this.$router.push({
      path: '/communityCenter/surveyList'
    })
  },

  reload() {
    this.loading = true

    this.surveyHeaderID = _get(this.$route.params, 'id', 0)

    this.surveyHeaderName = _get(this.$route.params, 'name', '')

    if (this.isDebug == true) console.debug('params surveyHeaderName=' + this.surveyHeaderName)

    this.rows = []

    this.loadSurveySections()

    this.loading = false
  },

  async createSurveySectionModal() {
    this.selectedSection = null
    this.toggle = true
  }
}

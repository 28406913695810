import { questionItem } from '@/services/Surveys/QuestionModal/store'
import $ from 'jquery'
import { notifyError } from '@/services/notify'

export const methods = {
  async reload() {
    this.loading = true

    await this.loadQuestionTypes()

    console.debug('this.question=' + JSON.stringify(this.question))

    if (this.question && this.question != undefined) {
      //Update
      this.buttonText = 'Save'

      this.formData.questionID = this.question.questionID
      this.formData.surveySectionID = this.question.surveySectionID
      this.formData.questionTypeID = this.question.questionTypeID
      this.formData.documentID = this.question.documentID
      this.formData.isMultipleOption = this.question.isMultipleOption
      this.formData.name = this.question.name

      this.newId = this.question.questionID

      this.loading = false
    } else {
      this.buttonText = 'Add'
      this.loading = false
    }

    this.loading = false
  },

  async loadQuestionTypes() {
    await questionItem.dispatch('getQuestionTypeList').then(({ list }) => {
      this.questionTypeList = list.map(m => ({
        value: m.value,
        label: this.processQuestionType(m.label)
      }))

      if (this.isDebug == true)
        console.debug('questionTypeList=' + JSON.stringify(this.questionTypeList))
    })
  },

  processQuestionType(qtype) {
    console.debug('processQuestionType=' + qtype)
    if (qtype) {
      if (qtype === 'Radio-Button') {
        return 'Single Selection'
      } else if (qtype === 'Check-Box') {
        return 'Multiple Selections'
      } else {
        return qtype
      }
    }
  },

  open() {
    this.formData = {
      questionID: 0,
      questionTypeID: 0,
      documentID: 0,
      name: '',
      isMultipleOption: false
    }

    this.loading = false
  },

  nextStep() {
    this.activeStep = 1
  },

  leaveForm() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
    this.loading = false
  },

  async submitQuestion() {
    if (this.formData && this.formData != undefined) {
      if (this.question && this.question != undefined && this.formData.questionID > 0) {
        await this.updateQuestion()
      } else {
        await this.addQuestion()
      }
    }
  },

  async addQuestion() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem processing this question.')
      return
    }

    this.loading = true

    await questionItem.dispatch('addQuestion', {
      surveySectionID: this.surveySectionID || 0,
      isMultipleOption: this.isMultipleOption || false,
      name: this.formData.name ? this.formData.name : '',
      questionTypeID: this.formData.questionTypeID || 0,
      done: ({ details }) => {
        if (details) {
          if (this.isDebug == true)
            console.debug('details newId=' + JSON.stringify(details) + ', newId=' + this.newId)

          this.leaveForm()
        }

        this.loading = false
      }
    })
  },

  confirmQuestionTypeChange(id) {
    this.$buefy.dialog.confirm({
      title: 'Question Type Change',
      message: `Changing the question type will delete your existing options. Are you sure you want to <b>proceed</b> with this change'?`,
      confirmText: 'Change Question Type',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.updateQuestionType(id)
    })
  },

  async updateQuestionType(id) {
    if (this.isDebug == true) console.debug('in updateQuestionType...' + id)

    await questionItem.dispatch('deleteOptionsByQuestion', {
      questionID: id,
      forceDelete: true,
      done: async () => {
        await this.updateQuestionPayload().then(() => {
          this.reload()
          this.loading = false
        })
        this.loading = false
      }
    })

    this.loading = false
  },

  async updateQuestion() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem updating this question.')
      return
    }

    //prompt for a physical deletion of existing options if changing question type from a multiple option to a single option.
    const questionType = this.questionTypeList.filter(f => f.value == this.formData.questionTypeID)
    if (questionType[0] && questionType[0].label != undefined) {
      const questionTypeName = questionType[0].label.toString()
      if (this.isDebug == true) console.debug('questionTypeName=' + questionTypeName)

      if (
        questionTypeName &&
        questionTypeName != undefined &&
        this.formData.questionTypeID != this.question.questionType.questionTypeID &&
        (questionTypeName == 'Scale' || questionTypeName == 'Text') &&
        (this.question.questionType.codeName == 'radiobutton' ||
          this.question.questionType.codeName == 'checkbox')
      ) {
        this.confirmQuestionTypeChange(this.formData.questionID)
        return
      }
    }

    this.loading = true
    await this.updateQuestionPayload()
    this.loading = false
  },

  async updateQuestionPayload() {
    const payload = {
      questionID: this.formData.questionID,
      surveySectionID: this.formData.surveySectionID,
      isMultipleOption: this.isMultipleOption || false,
      surveyHeaderID: this.question.surveyHeaderID,
      documentID: this.question.documentID,
      name: this.formData.name ? this.formData.name : '',
      questionTypeID: this.formData.questionTypeID || 0
    }

    await questionItem.dispatch('updateQuestion', {
      payload: payload,
      done: ({ details }) => {
        if (details) {
          this.newId = details.questionID
          this.question.questionID = details.questionID
        }

        this.loading = false
        this.leaveForm()
      }
    })
  }
}

<template>
  <div question-modal>
    <div class="is-size-4" v-if="formData">{{ buttonText }} Question</div>
    <b-steps class="question-modal" v-model="activeStep" :has-navigation="false" size="is-small">
      <b-step-item label="" add-question-step>
        <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(submitQuestion)">
              <fieldset>
                <div class="form-grid">
                  <div class="column is-12">
                    <div class="column is-12" v-if="questionTypeList">
                      <label class="label"
                        >Question Type<Info
                          title="Question Type"
                          information="Question types can be one of the following: Text, Muliple selections, Single selection, or a Scale."
                          imageOnePath="imageOnePath"
                          titleOne="Multiple Selections"
                          infoOne="One, or more, of the selections can be checked. This represents one, or more, answers to the question."
                          imageTwoPath="imageTwoPath"
                          titleTwo="Single Selections"
                          infoTwo="One, and only one, answer can be selected. This represents one answer to the question."
                          imageThreePath="imageThreePath"
                          titleThree="Text"
                          infoThree="You can type a paragraph of text to answer the question. You do not have to make a selection."
                          imageFourPath="imageFourPath"
                          titleFour="Scale"
                          infoFour="Select a number on a scale of 1 to N. N is defined in the question as the upper range."
                        />
                      </label>
                      <valid-select
                        placeholder="Question Type"
                        vid="questionTypeID"
                        v-model="formData.questionTypeID"
                        aria-required="The question type is required"
                        rules="required"
                      >
                        <option
                          v-for="option in questionTypeList"
                          :value="option.value"
                          :key="option.value"
                          >{{ option.label }}</option
                        >
                      </valid-select>
                    </div>
                    <div class="column is-12" style="padding:10px; white-space:nowrap;">
                      <label class="label"
                        >Question<Info
                          title="Question"
                          information="Represents the text of the survey question."
                      /></label>
                      <valid-input
                        v-model="formData.name"
                        name="name"
                        type="text"
                        vid="name"
                        placeholder="Name"
                        rules="required|max:500"
                      />
                    </div>
                    <div v-show="false" class="column is-12 pl-3 pt-5">
                      <b-checkbox
                        class="has-left-text"
                        v-model="formData.isMultipleOption"
                        @click.native="isMultipleOption = !isMultipleOption"
                        >Has Multiple Question Options</b-checkbox
                      >
                    </div>
                    <div class="pt-5">
                      <button
                        type="submit"
                        tests-id="survey-modal-button"
                        :disabled="loading"
                        class="button is-primary is-rounded"
                      >
                        {{ buttonText }}
                      </button>
                      <span class="pl-5"></span>
                      <button
                        v-if="newId"
                        type="button"
                        :disabled="loading"
                        class="button is-primary is-rounded"
                        @click="nextStep"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </b-step-item>
      <b-step-item label="" :clickable="newId ? true : false" ref="uploadpdf">
        <div ref="tab2" class="box is-12" v-if="newId">
          <section
            id="request-doc-upload"
            class="h-full overflow-auto mt-4 sm:p-8 w-full h-full flex flex-col"
          >
            <UploadModal :questionID="newId"> </UploadModal>
          </section>
          <b-button @click="leaveForm()" :style="{ float: 'right' }" type="is-primary"
            >Finish</b-button
          >
        </div>
      </b-step-item>
    </b-steps>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
import UploadModal from './../UploadModal/UploadModal'
import Info from '@/components/icons/Info'

export default {
  props: {
    surveySectionID: Number,
    question: Object
  },

  components: {
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider,
    UploadModal,
    Info
  },

  mounted() {
    if (this.question && this.question != undefined) {
      this.newId = this.question.questionID

      console.debug('mounted this.newId=' + this.newId)
    }
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
<style lang="scss">
.question-modal .step-items {
  display: none !important;
}
</style>

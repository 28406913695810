<template>
  <PageContentLayoutOnly :title="$t('section.title')" role="region">
    <div class="title">Survey Section Detail</div>
    <div class="subtitle">{{ surveyHeaderName }}</div>
    <div class="level">
      <div v-if="isSimple == false" class="level-left level-item">
        <b-button
          tests-id="add-survey-section-button"
          @click.prevent="createSurveySectionModal()"
          size="is-small"
          type="is-primary"
          rounded
        >
          Add Section
        </b-button>
      </div>
      <div class="level-right">
        <div class="level-item">
          <svg
            width="30"
            height="30"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
        </div>
        <div class="level-item">
          <b-button label="Back to Surveys" @click.prevent="returnToParent()" />
        </div>
      </div>
    </div>

    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      :expandableRows="true"
      :rowOnClick="rowOnClick"
      :_styles="{
        tableWrapper: {
          height: 'calc(100vh - 240px)',
          fontWeight: 'bold'
        }
      }"
    >
      <template v-slot:header>
        <div :style="{ width: '100%', height: '60px' }">
          <b-button
            type="is-ghost"
            @click="filters.show = !filters.show"
            :style="{ float: 'right', background: 'none', border: 0 }"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
        <Modal :toggle.sync="toggle">
          <SectionModal :section="selectedSection" />
        </Modal>
        <Modal :toggle.sync="questionToggle">
          <QuestionModal :surveySectionID="selectedSectionID" />
        </Modal>
      </template>
    </ModernTable>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { methods } from './keys/methods'
import { data } from './keys/data'
import SectionModal from './components/SectionModal'
import QuestionModal from './components/QuestionModal'
import Modal from '@/components/Modal'

export default {
  name: 'SurveySection',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    SectionModal,
    QuestionModal,
    Modal
  },

  data,
  methods,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    },
    questionToggle() {
      if (this.questionToggle == false) {
        this.reload()
        this.questionToggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    },
    questionOptionToggle() {
      if (this.questionOptionToggle == false) {
        this.reload()
        this.questionOptionToggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { section: { title: 'Survey Section' } }
    }
  }
}
</script>
